.App {
  text-align: center;
}

.App-logo {
  /* pointer-events: none; */
  /* width: 92px; */
  /* margin-top: 40px; */
  width: 100px;
  aspect-ratio: 1 / 1;
  transform: translateY(25%);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .App-logo {
    margin-left: 0;
    /* margin-right: 0; */
  }
}

.App-header {
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  color: white;
}

.App-header__top {
  background-color: var(--color-background);
  width: 100%;
  flex: 0 1 auto;
  height: 80px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.top-header-link {
  margin-left: auto;
  font-weight: 600;
  display: none;
}

@media (min-width: 768px) {
  .top-header-link {
    display: block;
  }
}

.App-header__top .App-header__content {
  flex-direction: row;
}

.App-header__bottom {
  min-height: 540px;
  display: flex;
  justify-content: center;
  position: relative;
}

.App-header__bottom .hero-background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./images/hero-background.png") no-repeat center center;
  opacity: 0.15;
}

.Hero-heading {
  margin-bottom: 16px;
  font-size: var(--font-size-h2);
}

.Hero-subheading {
  font-size: var(--font-size-h4);
}

@media (min-width: 560px) {
  .Hero-heading {
    font-size: var(--font-size-h1);
  }

  .Hero-subheading {
    font-size: var(--font-size-h3);
  }
}


.hero-buttons-container {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  flex-direction: column;
}

.hero-buttons-container .CTA-link {
  border: 2px solid rgba(255, 255, 255, 0.65);
}

.App-header__bottom .App-header__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
}

.CTA-link {
  background-color: var(--color-primary);
  color: #FFF;
  font-size: 1rem;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.CTA-link:not([disabled]):hover {
  background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
}

.CTA-link--secondary {
  background-color: var(--color-secondary);
}

.CTA-link:hover {
  text-decoration: none;
}

.App-header__content {
  align-items: center;
}

.Block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.Block-content {
  display: flex;
  flex: 1 1 auto;
  max-width: var(--max-width);
  padding-left: var(--grid-gap);
  padding-right: var(--grid-gap);
  flex-direction: column;
}

.Block-content__left-media {
  flex-direction: column;
  gap: var(--grid-gap);
}

.Block-content__left-lg-media {
  /* flex-direction:  */
  /* flex-direction: column; */
  gap: var(--grid-gap);
}

.Block-content__right-media {
  flex-direction: column;
  gap: var(--grid-gap);
}

.Block-content__grid {
  gap: var(--grid-gap);
  flex-direction: column;
}

.Block-content__grid-item {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Block-content__grid-item .Block-media {
  align-items: flex-start;
  flex: 0 0 auto;
}


.Block-content__grid-item .Block-content {
  padding-left: 0;
  padding-right: 0;
}

.Block-media {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Block-media img {
  border-radius: 4px;
}

.Block-heading {
  margin-bottom: 32px;
}

@media (min-width: 768px) {
  .hero-buttons-container {
    flex-direction: row;
  }

  .Block-heading {
    margin-bottom: 48px;
  }

  .Block-content__left-media {
    flex-direction: row;
  }

  .Block-content__right-media {
    flex-direction: row-reverse;
  }

  .Block-content__grid-item {
    width: 33.3%;
  }

  .Block-content__grid {
    flex-direction: row;
  }
}

@media (min-width: 960px) {
  .Block-content__left-lg-media {
    flex-direction: row;
  }
}

.Block-text {
  text-align: left;
  flex: 1 1 auto;
}

.Block-text a {
  text-decoration: underline;
}

.Block-text .CTA-link {
  text-decoration: none;
}

.Block-text-cta {
  margin-top: var(--grid-gap);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Block-text h3 {
  text-align: 'center'
}

@media (min-width: 768px) {
  .Block-text-cta {
    align-items: flex-start;
  }
}

.Video-container {
  aspect-ratio: 16 / 9;
  position: relative;
  width: 100%;
  background-color: var(--color-background-secondary);
  border-radius: 5px;
}

.Video-container iframe {
  border-radius: 5px;
  width: 100%;
}

.Footer {
  background-color: var(--color-primary);
  display: flex;
  width: 100%;
  padding: 40px 0 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.Footer-content {
  flex: 0 1 auto;
  display: flex;
  max-width: var(--max-width);
  list-style-type: none;
  color: white;
  margin-bottom: 0;
  padding-left: 0;
  font-size: var(--font-size-small);
  flex-direction: column;
}

@media (min-width: 480px) {
  .Footer-content {
    flex-direction: row;
  }
}

.Footer-content li {
  margin: 8px 16px;
}

.Footer-content a {
  color: white;
}

.Footer-logo-img {
  /* transform: translateY(-15%); */
  width: 32px;
  aspect-ratio: 1 / 1;
}

.Block-hans img {
  max-width: 320px;
  aspect-ratio: 20 / 21;
}

.Block-three-usps {
  background-color: var(--color-primary);
  color: white;
}

.Block-three-usps .Block-image {
  border-radius: 50%;
  /* border: 8px solid white; */
}

.Block-three-usps img {
  width: 180px;
  aspect-ratio: 1 / 1;

}

.Block-three-usps h3 {
  margin-top: 0;
}

.Block-three-usps ul {
  /* width: 200px; */
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.Block-video {
  width: 100%;
  display: block;
  padding-top: var(--grid-gap);
}

.Block-video .Block-content {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-top: 0;
  margin: 0 auto;
}

.Block-key-learning-method {
  background-color: var(--color-background-secondary)
}

.Block-key-learning-method img {
  max-width: 240px;
  border-radius: 50%;
  border: 2px solid var(--color-text-primary);
}

.Block-bpa img {
  max-width: 240px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.Block-free-coaching {
  background-color: var(--color-background-secondary);
}

.Block-signup {
  margin-bottom: 0;
}

.Block-signup img {
  max-width: 160px;
}

.signup-form {
  margin-top: 24px;
  margin-bottom: 4px;
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.signup-disclaimer {
  display: block;
  opacity: .85;
  margin-bottom: 16px;
}

@media (min-width: 480px) {
  .signup-form {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .Block-signup img {
    max-width: 256px;
    padding-left: 40px;
  }
}


@media (min-width: 960px) {
  .Block-bpa img {
    max-width: 320px;
  }

  .Block-key-learning-method img {
    max-width: 300px
  }

  .Block-program-overview .Video-container {
    min-width: 440px;
  }
}

@media (min-width: 1200px) {
  .Block-program-overview .Video-container {
    min-width: 720px;
  }
}
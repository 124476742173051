:root {
  --color-text-primary: #141e3f;
  --color-primary: #1B2956; /* bpa blue */
  --color-secondary: #952C2B; /* bpa red */
  --color-background: #ffffff;
  --color-background-secondary: #F2EDE6;
  --max-width: 960px;
  --grid-gap: 24px;

  --font-size-h1: 3.052rem;
  /* initial value, mobile first approach, to avoid overflowing headers */
  --font-size-h2: var(--font-size-h3);
  --font-size-h3: 1.953rem; 
  --font-size-h4: 1.563rem;
  --font-size-h5: 1.25rem;
  --font-size-base: 1rem;
  --font-size-small: 0.8rem;

}

@media screen and (min-width: 360px) {
  :root {
    --font-size-h2: 2.441rem;
  }
}
@media screen and (min-width: 480px) {
  :root {
    --grid-gap: 40px;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --grid-gap: 48px;
    --max-width: 1024px;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --grid-gap: 56px;
    --max-width: 1200px;
  }
}

[disabled] {
  cursor: not-allowed !important;
  opacity: 0.5;
}

body {
  margin: 0;
}


html {
  box-sizing: border-box;
  font-size: 100%; /* 16px */
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: var(--color-primary);
}

input {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: var(--color-text-primary);
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  font-size: var(--font-size-base)
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 1.38rem;
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 700;
  line-height: 1.3;
}

h1 {
  font-weight: 800;
}

h1 {
  margin-top: 0;
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

small,
.text_small {
  font-size: var(--font-size-small);
}

a {
  text-decoration: none;
  font-weight: 500;
  color: var(--color-primary);
}

a:hover {
  text-decoration: underline;
}

img {
  width: 100%;
  height: auto;
}

ul, ol {
  padding-left: 24px;
  margin: 0 0 24px 0;
}